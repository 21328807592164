<template>
    <div>
        <header-portrate />
        <div style="margin-bottom:70px;">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:25px !important;`">
            <v-col
                cols="12" md="12" sm="12" class="text-end">
                <b-button variant="success"  
                class="btn-sm addemployeecl" 
                :style="`width:150px;margin-`+lang.lalgin+`:6px;background:transparent;border:none`" flat 
                v-b-toggle.add_users 
                id="multiply-button"
                color="success">
                <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_user}}</div>
                </b-button>
            </v-col>
        </v-row>
        <v-row class="mainrow m-2">
            <!-- <v-col>
                <v-btn 
                    v-b-toggle.add_users
                    style="background: green !important;color:#FFF !important;float:left">
                    {{lang.add_user}}</v-btn>
            </v-col> -->
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.counter }}</td>
                            <td>{{ row.item.full_name }}</td>
                            <td>{{ row.item.username }}</td>
                            <td nowrap>{{ row.item.permission }}</td>
                            <td nowrap>{{ row.item.last_login_date }}</td>

                            <td class="text-center" style="width:60px;background:#000;border-bottom:1px solid #fff !important;">
                                <v-btn style="width:100px;font-size:0.2em;background:#000;color:#FFF;height:25px !important;margin-inline-end:5px;" @click="ViewItem(row.item)" v-b-toggle.update_users>{{lang.update}}</v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <addUser />
        <editUser ref="updateUser" />
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import addUser from '@/components/addUser.vue';
import editUser from '@/components/editUser.vue';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
  components: { addUser, BreadCrumbs, TabsComp, HeaderPortrate, Footer, editUser },
    data() {
        return {
            active_tab:0,
            
            
            
            tablerows: [

            ],
        }
    },
    methods: {
        ViewItem(item){
            this.$refs.updateUser.resetValue(item);
            // this.$router.push({path: '/updateuser/'+item.id})
        },
        getUsers(){
            const post = new FormData();
            post.append("type" , 'getUsers');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[user]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log(res);
                    this.tablerows = res.results.data.results;
                }
            )
        }
    },
    created() {
        this.getUsers();
        setInterval(
            () => {this.getUsers();},6000
        )
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items:function () {
                
            return {
                text: this.lang.users,
                disabled: true,
                to: '/settings/users-settings',
            }
        },
        headers: function(){
            return [
                {
                    text: '',
                    align: 'center',
                    filterable: false,
                    value: 'counter',
                },
                {
                    text: this.lang.user_name,
                    align: 'center',
                    filterable: false,
                    value: 'full_name',
                },
                {
                    text: this.lang.login_name,
                    align: 'center',
                    filterable: false,
                    value: 'username',
                },
                {
                    text: this.lang.permission,
                    align: 'center',
                    filterable: false,
                    value: 'permission',
                },
                {
                    text: this.lang.last_login,
                    align: 'center',
                    filterable: false,
                    value: 'last_login_date',
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    filterable: false,
                },
            ]
        },
    },
}
</script>